.btn-xs {
  @include button-size(.0625rem, .3125rem, .75rem, 1.5, .2rem);
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;

  &:hover, &:focus, &:active, &.active {
    color: #333;
    background-color: #ebebeb;
    border-color: #adadad;
  }
}

.btn.btn-square {
  border-radius: 0;
}

.btn-group {
  .btn + .btn.dropdown-toggle {
    border-left-color: #313335;

    &.btn-xs {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .btn-active-success {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
    font-size: 0.9rem;

    &:hover {
      color: #fff;
      background-color: #5cb85c;
      border-color: #4cae4c;
    }

    &.active {
      color: #fff;
      background-color: #449d44;
      border-color: #398439;

      &:hover {
        background-color: #398439;
        border-color: #255625;
      }
    }
  }
}

.btn-grp {
  & > .btn + .btn, & > .btn + .btn-group, & > .btn-group + .btn,
  & > .btn-group + .btn-group,
  & > .btn.dropdown-toggle + .dropdown-menu + .btn,
  & > .btn.dropdown-toggle + .dropdown-menu + .btn-group {
    margin-left: .5em;
  }
}

.btn-jma-gold.btn-inset {
  border-style: outset;
  border-width: 3px;
  box-shadow: 4px 4px 4px #555;

  color: #fff;
  background-color: #87754d;
  border-color: #96896e;
  max-width: 100%;

  font-weight: normal;
  white-space: inherit;
  padding: 6px 12px;
  line-height: 1.42857;
  border-radius: 0px;
  font-size: 14px;

  &:hover {
    background-color: #96896e;

    body > footer & {
      text-decoration: underline;
    }
  }

  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus {
    outline: 0;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
}